import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { CheckboxList, CustomCheckbox } from '../../inputs/CustomCheckbox'

const ChoiceShowOff = (props) => {
  const [columnView, setColumnView] = useState(false)
  const [showTokens, setShowTokens] = useState(false)
  const [checkMarks, setCheckMarks] = useState(false)
  const [showLineThrough, setShowLineThrough] = useState(false)
  const [showToggle, setShowToggle] = useState(false)
  const [appearanceRadio, setApperanceRadio] = useState(false)
  const [desktopGrow, setDesktopGrow] = useState(false)
  const calculateChecked = [
    columnView,
    showTokens,
    checkMarks,
    showLineThrough,
    showToggle,
    appearanceRadio,
    desktopGrow,
  ].filter((x) => x).length
  const isMobile = useMediaQuery({ query: '(max-width: 479px)' })
  useEffect(() => {
    const handleResize = () => {
      handleDesktopFlexState(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])
  const handleDesktopFlexState = (exclude) => {
    /*console.log(
      'exclude:',
      exclude,
      'desktopGrow:',
      desktopGrow,
      'isMobile:',
      isMobile
    )*/
    if (exclude) {
      setDesktopGrow(!desktopGrow)
    } else {
      if (isMobile) {
        setDesktopGrow(false)
      } else {
        return null
      }
    }
  }
  return (
    <>
      <CheckboxList
        h2={'Change options to see how the list below changes.'}
        tokens
        footnote={'We are all working with just one prop!'}
        count={calculateChecked}
      >
        <CustomCheckbox
          id={'appearance-radio'}
          labelFi={'Radio'}
          checked={appearanceRadio}
          changeValue={() => setApperanceRadio(!appearanceRadio)}
        />
        <CustomCheckbox
          id={'column-view'}
          labelFi={'Column'}
          checked={columnView}
          changeValue={() => setColumnView(!columnView)}
        />
        <CustomCheckbox
          id={'show-tokens'}
          labelFi={'Tokens'}
          checked={showTokens}
          changeValue={() => {
            setShowTokens(!showTokens)
            setDesktopGrow(false)
          }}
        />
        <CustomCheckbox
          id={'checkmarks-off'}
          labelFi={'Checkmarks Off'}
          checked={checkMarks}
          changeValue={() => setCheckMarks(!checkMarks)}
          disabled={showToggle}
        />
        <CustomCheckbox
          id={'line-through'}
          labelFi={'Line through'}
          checked={showLineThrough}
          changeValue={() => setShowLineThrough(!showLineThrough)}
        />
        <CustomCheckbox
          id={'toggles-view'}
          labelFi={'Toggles'}
          checked={showToggle}
          changeValue={() => setShowToggle(!showToggle)}
          disabled={checkMarks}
        />
        <CustomCheckbox
          id={'flex-grow-desktop'}
          labelFi={'Desktop Flex-Grow'}
          checked={desktopGrow}
          changeValue={() => handleDesktopFlexState(true)}
          disabled={!showTokens || isMobile}
        />
      </CheckboxList>
      <CheckboxList
        column={columnView}
        tokens={showTokens}
        noCheckMarks={checkMarks}
        name={appearanceRadio && 'radio-group'}
        h3={'This is where the magic happens'}
        lineThrough={showLineThrough}
        toggle={showToggle}
        desktopGrow={desktopGrow}
        footnote={'Try checking us as well.'}
      >
        <CustomCheckbox
          id={'choice1'}
          labelFi={'Javascript'}
          checked={!appearanceRadio && true}
          disabled
        />
        <CustomCheckbox id={'choice2'} labelFi={'React Native'} toggle />
        <CustomCheckbox id={'choice3'} labelFi={'React JSX'} toggle disabled />
        <CustomCheckbox
          id={'choice4'}
          labelFi={'React TSX'}
          toggle
          checked={!appearanceRadio && true}
          disabled
        />
        <CustomCheckbox
          id={'choice5'}
          labelFi={'Full Stack Development'}
          disabled
        />
        <CustomCheckbox id={'choice6'} labelFi={'Back-End Development'} />
        <CustomCheckbox id={'choice7'} labelFi={'Front-End Development'} />
        <CustomCheckbox id={'example-checkbox8'} labelFi={'UX/UI Design'} />
      </CheckboxList>
    </>
  )
}

export { ChoiceShowOff }

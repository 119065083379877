import React from 'react'

const CustomCheckbox = (props) => {
  const {
    labelFi,
    id,
    name,
    noCheckMarks,
    tokens,
    disabled,
    checked,
    toggle,
    lineThrough,
    changeValue,
  } = props
  const getClassName = () => {
    let classString = 'custom-checkbox'
    if (lineThrough) {
      classString += ' line-through'
    }
    if (name) {
      classString += ' radio-button'
    }
    if (noCheckMarks && tokens) {
      return (classString += ' without-checkmark')
    }
    if (noCheckMarks && tokens !== true) {
      return (classString += ' without-checkmark-bare')
    }
    if (toggle) {
      return (classString += ' switch-toggle')
    }
    return classString
  }
  return (
    <div className={getClassName()}>
      <input
        type={name ? 'radio' : 'checkbox'}
        id={id}
        name={name ? name : undefined}
        disabled={disabled}
        checked={checked}
        onChange={changeValue && ((e) => changeValue(e, id))}
        onClick={(e) => {
          e.target.focus()
        }}
      />
      <label htmlFor={id}>
        <span>
          {!toggle && (
            <svg width="12px" height="10px" className="checkmark-svg">
              <svg viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </svg>
            </svg>
          )}
        </span>
        <span>{labelFi}</span>
      </label>
    </div>
  )
}

const CheckboxList = (props) => {
  const {
    children,
    className,
    column,
    name,
    noCheckMarks,
    tokens,
    toggle,
    h2,
    h3,
    lineThrough,
    desktopGrow,
    footnote,
    count,
  } = props
  const getClassName = () => {
    let classString = 'checkbox-list'
    if (className) {
      classString += ` ${className}`
    }
    if (tokens) {
      classString += ' check-tokens'
    }
    if (column) {
      classString += ' column-directed'
    }
    if (desktopGrow) {
      classString += ' desktop-flex-grow'
    }
    return classString
  }
  const childrenWithExtraProp = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      name: name,
      noCheckMarks: noCheckMarks,
      tokens: tokens,
      lineThrough: lineThrough,
      toggle: toggle,
    })
  )
  //console.log(count)
  return (
    <>
      <div className="choice-list-container">
        {h2 && <h2>{h2}</h2>}
        {h3 && <h3>{h3}</h3>}
        {count | (count === 0) ? (
          <p className="selected-count">Selected: {count}</p>
        ) : null}
        <div className={getClassName()}>{childrenWithExtraProp}</div>
      </div>
      {footnote && <p className="footnote-caption">{footnote}</p>}
    </>
  )
}

export { CustomCheckbox, CheckboxList }

import React from 'react'
import Repeatable from 'react-repeatable'

const CountToggler = (props) => {
  const { onChange, count, addCount, removeCount, min, max } = props
  return (
    <div className="count-toggler">
      <Repeatable
        repeatDelay={250}
        repeatInterval={100}
        onClick={removeCount}
        onTouchStart={(e) => {
          e.preventDefault()
        }}
        onHold={removeCount}
      >
        <button
          className="remove pointer"
          type="button"
          onClick={(e) => {
            e.target.focus()
          }}
        >
          -
        </button>
      </Repeatable>
      <div className="serve-input-container">
        <input
          type="number"
          id="name"
          name="name"
          required
          min={min}
          max={max}
          pattern="\d*"
          className="toggler-input"
          value={isNaN(count) ? `${count}` : count}
          onChange={onChange}
        />
        <span></span>
      </div>
      <Repeatable
        repeatDelay={250}
        repeatInterval={100}
        onClick={addCount}
        onTouchStart={(e) => {
          e.preventDefault()
        }}
        onHold={addCount}
      >
        <button
          className="add pointer"
          type="button"
          onClick={(e) => {
            e.target.focus()
          }}
        >
          +
        </button>
      </Repeatable>
    </div>
  )
}

export { CountToggler }

const SCROLLABLE_TABLE_DATA = [
  {
    transaction_date: '2021-02-02',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1013',
  },
  {
    transaction_date: '2021-01-01',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1716',
  },
  {
    transaction_date: '2021-02-25',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1257',
  },
  {
    transaction_date: '2020-12-14',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1379',
  },
  {
    transaction_date: '2021-02-10',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1171',
  },
  {
    transaction_date: '2020-12-16',
    transaction_description: 'Lorem',
    transaction_status: 'Succeed',
    transaction_amount: '1974',
  },
  {
    transaction_date: '2020-12-28',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1096',
  },
  {
    transaction_date: '2021-02-15',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1548',
  },
  {
    transaction_date: '2021-02-17',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1012',
  },
  {
    transaction_date: '2020-12-11',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1104',
  },
  {
    transaction_date: '2020-12-30',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1333',
  },
  {
    transaction_date: '2021-01-16',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1824',
  },
  {
    transaction_date: '2021-02-16',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1634',
  },
  {
    transaction_date: '2020-12-07',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1477',
  },
  {
    transaction_date: '2021-01-18',
    transaction_description: 'Lorem',
    transaction_status: 'Error',
    transaction_amount: '1551',
  },
  {
    transaction_date: '2021-03-24',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1147',
  },
  {
    transaction_date: '2021-02-10',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1875',
  },
  {
    transaction_date: '2021-03-24',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1585',
  },
  {
    transaction_date: '2021-02-13',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1419',
  },
  {
    transaction_date: '2021-02-16',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1845',
  },
  {
    transaction_date: '2020-12-14',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1456',
  },
  {
    transaction_date: '2020-12-23',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1436',
  },
  {
    transaction_date: '2021-03-30',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1672',
  },
  {
    transaction_date: '2020-12-13',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1551',
  },
  {
    transaction_date: '2020-12-07',
    transaction_description: 'Lorem',
    transaction_status: 'Succeed',
    transaction_amount: '1432',
  },
  {
    transaction_date: '2021-01-10',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1888',
  },
  {
    transaction_date: '2021-01-29',
    transaction_description: 'Lorem',
    transaction_status: 'Error',
    transaction_amount: '1411',
  },
  {
    transaction_date: '2021-02-05',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1976',
  },
  {
    transaction_date: '2020-12-15',
    transaction_description: 'Lorem',
    transaction_status: 'Error',
    transaction_amount: '1492',
  },
  {
    transaction_date: '2020-12-18',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1682',
  },
  {
    transaction_date: '2020-12-08',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1792',
  },
  {
    transaction_date: '2021-03-21',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1571',
  },
  {
    transaction_date: '2020-12-27',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1011',
  },
  {
    transaction_date: '2021-03-12',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1136',
  },
  {
    transaction_date: '2020-12-13',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1388',
  },
  {
    transaction_date: '2021-01-08',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1585',
  },
  {
    transaction_date: '2021-03-07',
    transaction_description: 'Lorem',
    transaction_status: 'Error',
    transaction_amount: '1562',
  },
  {
    transaction_date: '2021-01-31',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1052',
  },
  {
    transaction_date: '2021-01-09',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1343',
  },
  {
    transaction_date: '2021-01-11',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1005',
  },
  {
    transaction_date: '2021-02-23',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1921',
  },
  {
    transaction_date: '2021-03-07',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1988',
  },
  {
    transaction_date: '2021-03-02',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1090',
  },
  {
    transaction_date: '2021-04-01',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1829',
  },
  {
    transaction_date: '2021-03-21',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1121',
  },
  {
    transaction_date: '2021-03-24',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1429',
  },
  {
    transaction_date: '2021-02-16',
    transaction_description: 'Lorem',
    transaction_status: 'Error',
    transaction_amount: '1565',
  },
  {
    transaction_date: '2021-02-14',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1086',
  },
  {
    transaction_date: '2021-03-02',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1553',
  },
  {
    transaction_date: '2021-02-11',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1144',
  },
  {
    transaction_date: '2021-01-06',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1698',
  },
  {
    transaction_date: '2020-12-21',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1167',
  },
  {
    transaction_date: '2021-02-17',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1457',
  },
  {
    transaction_date: '2020-12-06',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1771',
  },
  {
    transaction_date: '2021-03-27',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1234',
  },
  {
    transaction_date: '2020-12-07',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1332',
  },
  {
    transaction_date: '2021-03-03',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1627',
  },
  {
    transaction_date: '2020-12-12',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1587',
  },
  {
    transaction_date: '2021-03-08',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1674',
  },
  {
    transaction_date: '2021-01-17',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1051',
  },
  {
    transaction_date: '2020-12-20',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1872',
  },
  {
    transaction_date: '2021-03-28',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1640',
  },
  {
    transaction_date: '2020-12-16',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1651',
  },
  {
    transaction_date: '2020-12-09',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Error',
    transaction_amount: '1127',
  },
  {
    transaction_date: '2020-12-22',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1234',
  },
  {
    transaction_date: '2020-12-17',
    transaction_description: 'Lorem',
    transaction_status: 'Succeed',
    transaction_amount: '1686',
  },
  {
    transaction_date: '2021-03-07',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1679',
  },
  {
    transaction_date: '2021-03-25',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1614',
  },
  {
    transaction_date: '2021-02-01',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1360',
  },
  {
    transaction_date: '2020-12-22',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1137',
  },
  {
    transaction_date: '2020-12-12',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1431',
  },
  {
    transaction_date: '2021-03-17',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1237',
  },
  {
    transaction_date: '2021-01-01',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1071',
  },
  {
    transaction_date: '2021-03-13',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1714',
  },
  {
    transaction_date: '2021-03-01',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1484',
  },
  {
    transaction_date: '2020-12-31',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1494',
  },
  {
    transaction_date: '2021-02-20',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1980',
  },
  {
    transaction_date: '2021-01-15',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1816',
  },
  {
    transaction_date: '2021-03-14',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1620',
  },
  {
    transaction_date: '2021-01-23',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1988',
  },
  {
    transaction_date: '2021-02-10',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1044',
  },
  {
    transaction_date: '2020-12-02',
    transaction_description: 'Lorem',
    transaction_status: 'Succeed',
    transaction_amount: '1417',
  },
  {
    transaction_date: '2021-03-16',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Succeed',
    transaction_amount: '1094',
  },
  {
    transaction_date: '2021-03-19',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1031',
  },
  {
    transaction_date: '2021-03-31',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1185',
  },
  {
    transaction_date: '2021-01-03',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1721',
  },
  {
    transaction_date: '2021-03-18',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1811',
  },
  {
    transaction_date: '2020-12-17',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1591',
  },
  {
    transaction_date: '2021-02-16',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1217',
  },
  {
    transaction_date: '2021-02-19',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1473',
  },
  {
    transaction_date: '2021-01-14',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Warning',
    transaction_amount: '1729',
  },
  {
    transaction_date: '2021-02-10',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Succeed',
    transaction_amount: '1784',
  },
  {
    transaction_date: '2021-02-27',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1405',
  },
  {
    transaction_date: '2020-12-15',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Error',
    transaction_amount: '1919',
  },
  {
    transaction_date: '2020-12-27',
    transaction_description: 'Lorem ipsum dolor sit',
    transaction_status: 'Warning',
    transaction_amount: '1103',
  },
  {
    transaction_date: '2021-02-08',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Warning',
    transaction_amount: '1763',
  },
  {
    transaction_date: '2021-03-17',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1296',
  },
  {
    transaction_date: '2020-12-27',
    transaction_description: 'Lorem ipsum',
    transaction_status: 'Error',
    transaction_amount: '1065',
  },
  {
    transaction_date: '2021-01-06',
    transaction_description: 'Lorem',
    transaction_status: 'Warning',
    transaction_amount: '1178',
  },
  {
    transaction_date: '2021-01-10',
    transaction_description: 'Lorem ipsum dolor',
    transaction_status: 'Succeed',
    transaction_amount: '1571',
  },
]

export { SCROLLABLE_TABLE_DATA }

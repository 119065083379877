import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { SCROLLABLE_TABLE_DATA } from '../../../data/mockdata/scrollable-table'

const ScrollableTable = (props) => {
  const { height = '30', fullPage, id } = props
  const isNarrow = useMediaQuery({ query: '(max-width: 575px)' })
  const sortedTableData = SCROLLABLE_TABLE_DATA.sort(
    (a, b) => new Date(b.transaction_date) - new Date(a.transaction_date)
  )
  let previousDate = null
  return (
    <>
      {/*<div className="table-fix"></div>*/}
      <div
        className={`table-wrapper ${fullPage && 'full-page-view'}`}
        style={{ ...(!fullPage && { height: `${height}rem` }) }}
        id={id}
      >
        <table>
          <thead>
            <tr>
              {isNarrow ? (
                <th>
                  <div>
                    <div>Päivämäärä</div>
                    <div>Summa</div>
                  </div>
                </th>
              ) : (
                <>
                  <th>Päivämäärä</th>
                  <th>Selite</th>
                  <th className="centered">Tila</th>
                  <th className="right-aligned">Summa</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedTableData.map((item, id) => {
              const {
                transaction_date,
                transaction_amount,
                transaction_description,
                transaction_status,
              } = item
              const previousDateCopy = previousDate
              previousDate = transaction_date
              return (
                <React.Fragment key={`transaction-${id}`}>
                  {((previousDateCopy &&
                    new Date(previousDateCopy).getMonth() !==
                      new Date(transaction_date).getMonth()) ||
                    id === 0) && (
                    <tr className="scrollable-table-row scrollable-table__sticky-divider">
                      <td colSpan="100%">
                        {new Date(transaction_date).toLocaleDateString(
                          'fi-FI',
                          { month: 'long' }
                        )}
                      </td>
                    </tr>
                  )}
                  <tr className="scrollable-table-row">
                    <td>
                      {new Date(transaction_date).toLocaleDateString('fi-FI', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </td>
                    <td className="break-words">{transaction_description}</td>
                    <td className="centered">{transaction_status}</td>
                    <td className="right-aligned">
                      {parseFloat(transaction_amount).toLocaleString('fi-FI', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
        <div className="summary-break">
          <a
            href="/"
            rel="noreferrer noopener"
            aria-label="More transactions"
            className={`disabled`}
          >
            Lataa lisää tapahtumia
          </a>
        </div>
        {fullPage && (
          <ScrollableTableSummary
            sortedTableData={sortedTableData}
            fullPage={fullPage}
          />
        )}
      </div>
      {!fullPage && (
        <ScrollableTableSummary
          sortedTableData={sortedTableData}
          fullPage={fullPage}
        />
      )}
    </>
  )
}

const ScrollableTableSummary = (props) => {
  const { sortedTableData, fullPage } = props
  return (
    <div className={`table-summary ${fullPage && 'stick-bottom'}`}>
      <div className="table-summary-content">
        <div>Näytetään: {sortedTableData.length} tapahtumaa</div>
        <div className="date-range-container">
          Valittu ajanjakso:{' '}
          <span className="table-date-range">
            {new Date(
              sortedTableData[sortedTableData.length - 1].transaction_date
            ).toLocaleDateString('fi-FI', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}{' '}
            -{' '}
            {new Date(sortedTableData[0].transaction_date).toLocaleDateString(
              'fi-FI',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )}
          </span>
        </div>
      </div>
      {/*<div>Näytä lisää tapahtumia</div>*/}
    </div>
  )
}

export { ScrollableTable, ScrollableTableSummary }

import React from 'react'

const SwitchToggle = (props) => {
  const { children, style } = props
  return (
    <div className={`toggle-options switch-ios`} style={style}>
      {children}
      <div></div>
    </div>
  )
}

const SwitchToggleItem = (props) => {
  const { id, name, label, defaultItem, checked, onClick } = props
  return (
    <>
      <input
        id={`option-toggle-${id}`}
        name={name}
        type="radio"
        checked={checked}
        defaultChecked={defaultItem && `defaultChecked`}
        onClick={(e) => e.target.focus()}
      />
      <label
        htmlFor={`option-toggle-${id}`}
        onClick={onClick}
        tabIndex={-1}
        aria-hidden="true"
      >
        {label}
      </label>
    </>
  )
}

export { SwitchToggle, SwitchToggleItem }
